form {
  font-family: 'Noto Sans', serif;
}

.whisper-regular {
  font-family: "Whisper", cursive;
  font-weight: 400;
  font-style: normal;
}


.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("https://i.ibb.co/8KcyrxX/fond.png");
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  /*background-position-y: -150%;*/
  z-index: -90;
  opacity: 0.8;
  /*background-color: #F5F6FA;*/
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Exo';
  src: local('Exo'), url('../public/Exo/Exo-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto_Sans';
  src: local('Exo'), url('../public/Noto_Sans/NotoSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

p,a, button {
  color: white;
  font-family: 'Noto_Sans', serif;
}

h1,h2, textarea {
  font-family: 'Exo', serif;
  color:white

}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  margin-left: 50%;
  margin-top: 15%;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left:50%;
  inset: 0;
  border-radius: 50%;
  border: 5px solid #1CBDB4;
  animation: prixClipFix 4s linear infinite ;
}
.loader::after{
  border-color: #DD6E79;
  animation: prixClipFix 5s linear infinite , rotate 0.7s linear infinite reverse;
  inset: 6px;
}


#chatContainer::-webkit-scrollbar {
  width: 26px;
}
@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

/*button {*/
/*  background-color: #FEA746; !* Couleur de fond contrastée *!*/
/*  color: white;*/
/*  height: 40px;*/
/*  width: auto;*/
/*  border-radius: 10px;*/
/*  margin: auto;*/
/*}*/

#photoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

#photoUser {
  max-height: 250px;
  width: auto;
  border-radius: 10px;


}

input:focus {
  outline: none; /* Supprime la bordure de focus par défaut */
  /* Ajoute ici d'autres styles si nécessaire, par exemple pour définir une bordure spécifique ou aucune */
  border: 2px solid #DD6E79; /* Exemple pour redéfinir la bordure, optionnel */
  box-shadow: 5px 0 10px #DD6E79;
}


.btn-upload {
  background-color: transparent;
}

/*#nav {*/
/*  border-bottom: 2px solid white;*/
/*  padding-bottom: 20px;*/
/*}*/

#imgTooHeavy {

  border: red 2px solid;
}

.hidden-message {
  display: none;
}
